import {isPlatform} from "@ionic/react";

export const API_ENDPOINT: string = "https://stage.extranet360.com/";
export const TRAVEL_ALLOWANCE_API_ENDPOINT: string = "https://travel-allowance-stage.extranet360.com/";
export const CLIENT_ID: string = "1_68x6uk6fagco080080o0wgkkoo80oos08c8c0k0gsgkks8ocok";
export const CLIENT_SECRET: string = "3ojxha89h884ogksgs00oo08c08c44kwowg804ow4wo0csokg4";
export const SENTRY_DSN: string = "https://484066f47bd949cfb1ec46250988a9ba:88e0329bf17b4de2b30ffcd4d5abc35d@sentry.io/1293509";
export const APP_STORE_URL: string = "https://apps.apple.com/us/app/exact-people-go/id1589408961";
export const GOOGLE_PLAY_URL: string = "https://play.google.com/store/apps/details?id=com.exactsystems.exact.people";
export const GOOGLE_MAPS_API_KEY: string = "AIzaSyDaIDv0NNVIxZZXFiRn3C9agJ9ZC7OxtwM";

export const getAppId = () => {
    if (isPlatform('ios')) {
        return 'com.exactsystems.exactpeoplego';
    } else {
        return 'com.exactsystems.exact.people';
    }
}
