import React, {useEffect, useState} from 'react';
import {IonCol, IonIcon, IonItem, IonRow, IonSelect, IonSelectOption} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import moment from "moment";
import {Controller, useFormContext} from "react-hook-form";

import {Car} from "@models/travelAllowance/car";
import {BackRideForm, RideFormData} from "@models/travelAllowance/ride";

import Accordion from "@components/accordion/accordion";
import DateInput from '@components/form/input/date.component';

import CalendarIcon from '@assets/images/travelAllowance/calendar.svg';
import CityAutocomplete from "@components/google/cityAutocomplete/cityAutocomplete.component";
import PassengersFormField from "@app/travelAllowance/ride/components/passengersFormField.component";

import {useRideFormContext} from "@context/rideForm.context";

import {useEvent} from "@hooks/useEvent";

import {StyledIonLabel} from "@components/form/input/label.style";
import {StyledIonCheckbox} from "@components/form/input/checkbox.style";
import {StyledInput, StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";
import {EventType} from "@enums/eventType";

type RideFormFieldsProps = {};

const BackRideFormFields: React.FC<RideFormFieldsProps> = ({}: RideFormFieldsProps) => {
    const {t} = useTranslation();
    const {register, control, getValues, setValue} = useFormContext<RideFormData>();
    const {listen} = useEvent();

    const rideFormContext = useRideFormContext();

    const [dateStart, setDateStart] = useState<string>('');
    const [checkedBackRide, setCheckedBackRide] = useState<boolean>(false);
    const [backRide, setBackRide] = useState<Omit<BackRideForm, "dateStart">>();

    useEffect(() => {
        listen(EventType['REFRESH.BACK_RIDE_FORM_FIELDS'], handleBackRideSet);
    }, []);

    const handleBackRideSet = () => {
        const { ride } = getValues();

        if (!ride) return;

        const { route, passengers, ...rideRest } = ride;
        const passengersTemp = checkedBackRide ? backRide?.passengers : passengers;

        setBackRide({
            route: {
                startingPlace: ride.route.destinationPlace,
                destinationPlace: ride.route.startingPlace,
                startingPlaceCountry: ride.route.destinationPlaceCountry,
                destinationPlaceCountry: ride.route.startingPlaceCountry,
                distance: ride.route.distance,
                isLocal: ride.route.isLocal,
                coordinator: ride.route.coordinator,
                placeOfService: ride.route.placeOfService,
            },
            passengers: passengersTemp,
            ...rideRest
        });

        setDateStart(ride.dateStart);
        setValue('backRide.route.isLocal', !!ride?.route?.isLocal);
    };

    const handleDateChange = (dateInput: string, onChangeCallback: (...event: any[]) => void) => {
        dateInput = moment(dateInput).format('YYYY-MM-DD')

        setDateStart(dateInput);
        onChangeCallback(dateInput);
    }

    return (
        <>
            <Accordion isOpen={true} allowToggle={true}
                       title={t('travelAllowance.ride.backRide')}>
                <IonRow>
                    <IonItem lines="none">
                        <StyledIonLabel
                            className="ion-text-wrap">{t('travelAllowance.ride.addBackRide')}</StyledIonLabel>
                        <StyledIonCheckbox
                            slot="start"
                            onIonChange={() => {
                                setCheckedBackRide(prevState => !prevState);
                            }}
                        />
                    </IonItem>
                </IonRow>
                {
                    checkedBackRide &&
                    <>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.startDate')}
                            </IonCol>
                        </IonRow>
                        <StyledInputGroup>
                            <IonCol size="10" className="label">
                                <Controller
                                    name={'backRide.dateStart'}
                                    control={control}
                                    defaultValue={dateStart !== '' ? dateStart : ''}
                                    rules={{required: true}}
                                    render={({onChange}) => (
                                        <DateInput
                                            presentation="date"
                                            format="DD.MM.YYYY"
                                            onChange={value => {
                                                handleDateChange(value, onChange);
                                            }}
                                            max={moment().format()}
                                            cancelText={t("common.dateCancel")}
                                            doneText={t("common.dateDone")}
                                            placeholder={t("common.select")}
                                            value={dateStart !== '' ? dateStart : ''}
                                        />
                                    )}
                                />
                            </IonCol>
                            <IonCol size="2" className="label">
                                <StyledInputUnit icon={true}>
                                    <IonIcon slot="icon-only" icon={CalendarIcon}/>
                                </StyledInputUnit>
                            </IonCol>
                        </StyledInputGroup>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('travelAllowance.backRide.startingPlace')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label">
                                <CityAutocomplete disabled={true}
                                                  defaultValue={backRide?.route.startingPlace}/>
                                <input ref={register}
                                       name={'backRide.route.startingPlace'}
                                       type={"hidden"}
                                       value={backRide?.route.startingPlace}/>
                            </IonCol>
                            <input ref={register}
                                   name={'backRide.route.startingPlaceCountry'}
                                   type={"hidden"}
                                   value={backRide?.route.startingPlaceCountry}
                            />
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.destinationPlace')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label">
                                <CityAutocomplete disabled={true}
                                                  defaultValue={backRide?.route.destinationPlace}/>
                                <input ref={register}
                                       name={'backRide.route.destinationPlace'}
                                       type={"hidden"}
                                       value={backRide?.route.destinationPlace}/>
                            </IonCol>
                            <input ref={register}
                                   name={'backRide.route.destinationPlaceCountry'}
                                   type={"hidden"}
                                   value={backRide?.route.destinationPlaceCountry}
                            />
                        </IonRow>
                        <input ref={register({setValueAs: v => v === 'true'})}
                               name={'backRide.route.isLocal'}
                               type={"hidden"}/>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.distance')}
                            </IonCol>
                        </IonRow>
                        <StyledInputGroup>
                            <IonCol size="10" className="label">
                                <StyledInput
                                    ref={register({valueAsNumber: true})}
                                    name={'backRide.route.distance'}
                                    defaultValue={backRide?.route.distance || undefined}
                                    readOnly={true}
                                />
                            </IonCol>
                            <IonCol size="2" className="label">
                                <StyledInputUnit>km</StyledInputUnit>
                            </IonCol>
                        </StyledInputGroup>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.coordinator')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select-full-width">
                                <StyledInput
                                    placeholder={rideFormContext?.coordinator?.name ?? ''}
                                    disabled={true}
                                ></StyledInput>
                                <input ref={register}
                                       name={'backRide.route.coordinator'}
                                       type={"hidden"}
                                       value={rideFormContext?.coordinator?.id ?? ''}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.placeOfService')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select-full-width">
                                <StyledInput
                                    placeholder={rideFormContext?.placeOfService?.name ?? ''}
                                    disabled={true}
                                >
                                </StyledInput>
                                <input ref={register}
                                       name={'backRide.route.placeOfService'}
                                       type={"hidden"}
                                       value={rideFormContext?.placeOfService?.id ?? ''}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.car')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select-full-width">
                                <IonSelect
                                    placeholder={t('travelAllowance.backRide.selectCar')}
                                    value={backRide?.car}
                                    disabled={true}
                                >
                                    {rideFormContext.cars.map((car: Car) => {
                                        return <IonSelectOption
                                            key={car.id}
                                            value={car.id}>{car.brand} {car.model} {car.licensePlate}</IonSelectOption>
                                    })}
                                </IonSelect>
                                <input ref={register({valueAsNumber: true})}
                                       name={'backRide.car'}
                                       type={"hidden"}
                                       value={backRide?.car}/>
                            </IonCol>
                        </IonRow>
                        <PassengersFormField prefix={'backRide'}
                                             defaultPassengers={backRide?.passengers}
                                             coordinator={rideFormContext.coordinator}
                                             placeOfService={rideFormContext.placeOfService}
                        />
                    </>
                }
            </Accordion>
        </>
    );
};

export default BackRideFormFields;
