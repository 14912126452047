import React, {useContext, useEffect, useState} from 'react';
import {
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton, IonToast, useIonViewDidEnter,
	useIonViewWillEnter,
} from '@ionic/react';
import { Redirect, Route, RouteComponentProps, useHistory } from 'react-router-dom';
import ProfileTab from './tabs/profile/profileTab.component';
import ReportsTab from './tabs/reports/reportsTab.component';
import OffersTab from './tabs/offers/offersTab.component';
import PlanningTab from './tabs/planning/planningTab.component';
import CommunicatorTab from './tabs/communicator/communicatorTab.component';
import NotificationsTab from './tabs/notifications/notificationsTab.component';
import { Links } from '../links';
import { StyledIonTabs } from '../../components/content/content.style';
import ExactPayImage from '../../assets/images/exact-pay-gray.svg';
import ExactPayImageActive from '../../assets/images/exact-pay-active.svg';
import ReportsImage from '../../assets/images/reports.svg';
import ReportsImageActive from '../../assets/images/reports-active.svg';
import JobOffersImage from '../../assets/images/job-offers.svg';
import JobOffersImageActive from '../../assets/images/job-offers-active.svg';
import NotificationsImage from '../../assets/images/notifications.svg';
import NotificationsImageActive from '../../assets/images/notifications-active.svg';
import AvailabilityImage from '../../assets/images/availability.svg';
import AvailabilityImageActive from '../../assets/images/availability-active.svg';
import ScheduleImage from '../../assets/images/schedule.svg';
import ScheduleImageActive from '../../assets/images/schedule-active.svg';
import WalletImage from '../../assets/images/wallet.svg';
import WalletImageActive from '../../assets/images/wallet-active.svg';
import HoursRegistryImage from '../../assets/images/hours-registry.svg';
import HoursRegistryImageActive from '../../assets/images/hours-registry-active.svg';
import ContractsImage from '../../assets/images/contracts.svg';
import ContractsImageActive from '../../assets/images/contracts-active.svg';
import BillingsImage from '../../assets/images/billings.svg';
import BillingsImageActive from '../../assets/images/billings.svg';
import PlanningImage from '../../assets/images/planning.svg';
import PlanningImageActive from '../../assets/images/planning-active.svg';
import MyDataImage from '../../assets/images/my-data.svg';
import MyDataImageActive from '../../assets/images/my-data-active.svg';
import AvatarImage from '../../assets/images/avatar.svg'
import { useTranslation } from 'react-i18next';
import {Preferences} from "@capacitor/preferences";
import {SplashScreen} from "@capacitor/splash-screen";
import SettingsTab from './tabs/settings/settingsTab.component';
import LanguageTab from './tabs/language/languageTab.component';
import CitiesTab from './tabs/cities/citiesTab.component';
import {appStatusBarUpdate} from "../../utils/tools/statusbar";
import useNavigation from '../../services/navigation.service';
import {StyledFullMenuButton, StyledMenuButton, StyledNewNotification} from './mainPage.style';
import {hasUnreadNotifications} from '../../services/push.service';
import TeamMemberPhoneModal from "../../modals/teamMemberPhone/teamMemberPhone.component";
import {hasPhone} from "../../services/teamMember.service";
import {compareVersions} from "../../services/validation.service";
import {log} from "../../services/firebaseAnalytics.service";
import {hasForeignTermsSignContract, hasTisaxContract} from "../../services/contract.service";
import TisaxModal from "../../modals/sign/tisax.component";
import SignModal, {ISignModalConfigration} from "../../modals/sign/sign.component";
import {foreignTermsSave, foreignTermsSaveSign, tisaxSave, tisaxSaveSign} from "../../services/worker.service";
import ForeignTermsSignModal from "../../modals/sign/foreignTerms.component";
import {getMenu} from "../../services/menu.service";
import {MenuItem} from "../../models/menu";
import MenuModal from "../../modals/menu/menu.component";
import {checkboxOutline, bagOutline} from "ionicons/icons";
import TimetablePage from "../timetablePage/timetablePage.component";
import ContractsPage from "../contractPage/contractsPage.component";
import AvailabilityPage from "../availabilityPage/availabilityPage.component";
import WalletPage from "../walletPage/walletPage.component";
import HoursRegistryPage from "../hoursRegistryPage/hoursRegistryPage.component";
import LoansPage from "../loanPage/loansPage.coponent";
import moment from "moment";
import {logout, useAuth} from "../../services/auth.service";
import BillingPage from "../billingPage/billingPage.component";
import MyDataPage from "../myData/myDataPage.component";
import HoursWorkedPage from "../hoursWorkedPage/hoursWorkedPage.component";
import HoursWorkedImage from '../../assets/images/time-clock.svg';
import HoursWorkedImageActive from '../../assets/images/time-clock-active.svg';
import MoreImage from '../../assets/images/more.svg';
import WorkerWarehousePage from "../workerWarehouse/workerWarehousePage.component";
import RecruitmentPage from "../recruitmentPage/recruitmentPage.component";
import AuditReports from "../auditReport/auditReports.component";
import InconsistencyModulePage from "../inconsistencyModulePage/inconcistencyModulePage.component";
import ReferFriendsPage from "../referFriendsPage/referFriendsPage.component";
import RideListPage from "@app/travelAllowance/ride/pages/rideListPage.component";
import CarListPage from "@app/travelAllowance/car/pages/carListPage.component";
import RideAddPage from "@app/travelAllowance/ride/pages/rideAddPage.component";
import CarAddPage from "@app/travelAllowance/car/pages/carAddPage.component";
import RideRouteAddPage from "@app/travelAllowance/rideRoute/pages/rideRouteAddPage.component";
import RideRouteListPage from "@app/travelAllowance/rideRoute/pages/rideRouteListPage.component";
import RideEditPage from "@app/travelAllowance/ride/pages/rideEditPage.component";
import RidePreviewPage from "@app/travelAllowance/ride/pages/ridePreviewPage.component";
import CarEditPage from "@app/travelAllowance/car/pages/carEditPage.component";
import RideRouteEditPage from "@app/travelAllowance/rideRoute/pages/rideRouteEditPage.component";
import SubMenuPage from "@app/subMenuPage/subMenuPage.component";
import RideReportListPage from "@app/travelAllowance/rideReport/pages/rideReportListPage.component";

interface MainPageManager {
	setAvatarUrl: Function;
	setNotificationBadge: Function;
	setMenuItems: Function;
	getNotificationBadge: Function;
	setShowTokenExpirationToast: Function;
}

const contextMainPage: MainPageManager = {
	setAvatarUrl: () => {

	},
	setNotificationBadge: () => {

	},
	setMenuItems: () => {

	},
	getNotificationBadge: () => {

	},
	setShowTokenExpirationToast: () => {

	}
};

export const MainPageContext = React.createContext<MainPageManager>(contextMainPage);

const MainPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
	const { t } = useTranslation();
    const [avatarUrl, setAvatarUrl] = useState<any>();
	const [showPhoneModal, updateShowPhoneModal] = useState<boolean>(false);
	const [showAppUpdateModal, updateShowAppUpdateModal] = useState<boolean>(false);
	const [showTisaxModal, updateShowTisaxModal] = useState<boolean>(false);
	const [showForeignTermsModal, updateShowForeignTermsModal] = useState<boolean>(false);
	const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
	const [showMenuModal, updateShowMenuModal] = useState<boolean>(false);
	const [showTokenExpirationToast, setShowTokenExpirationToast] = useState(false);
	const [tokenExpirationToastDuration, setTokenExpirationToastDuration] = useState<number>(60000); // 60 sec
	const auth = useAuth();

	useIonViewWillEnter( () => {
        SplashScreen.hide();
        appStatusBarUpdate();
    });

	const history = useHistory();
    const navigation = useNavigation(history);

	useEffect(() => {
		const unblock = history.block((location: any) => {
			const blockNavigation = navigation.getBlockNavigation()
			if (!blockNavigation) {
				return undefined;
			}

			navigation.setDestinationUrl(`${location.pathname}${location.search}`);

			let block = blockNavigation();

			if (block) {
				return false;
			} else {
				unblock();
				navigation.setBlockNavigation(undefined);
				navigation.setUnblockNavigation(undefined);
				return undefined;
			}
		});

		navigation.setUnblockNavigation(unblock);

		return unblock;
	});

	const getAvatarUrl = async () => {
		let avatarUrl = await Preferences.get({'key': 'avatar_url'});
		setAvatarUrl(avatarUrl.value);
	};

	const fetchMenu = async () => {
		await getMenu().then(response => {
				setMenuItems(response.data);
			});
	};

	const redirectToMember = async () => {
		let registerAlreadyWorker = await Preferences.get({'key': 'register_already_worker'});
		if (registerAlreadyWorker.value === '1') {
			await Preferences.remove({'key': 'register_already_worker'});
			history.push(Links.becameTeamMember);
		}
	};

	// const redirectToTerms = async () => {
	// 	let terms = await termsAccepted();
	// 	if (terms == '0') {
	// 		updateShowApprovals(true);
	// 	}
	// };

	const openCompareVersions = async () => {
		let version = null;
		await compareVersions().then(value => {
			version = value;
		});

		if (version) {
			// updateShowAppUpdateModal(version);
			history.replace(Links.appUpdate);
		}
	}

	const openTisax = async () => {
		let object = await Preferences.get({
			'key': 'tisax_need_sign',
		});

		let needTisaxSign = null;
		if (object.value === null) {
			needTisaxSign = await hasTisaxContract();

			await Preferences.set({
				'key': 'tisax_need_sign',
				'value': needTisaxSign ? '1' : '0'
			});
		} else {
			needTisaxSign = object.value === '1';
		}

		if (needTisaxSign) {
			updateShowTisaxModal(true);
		}
	}

	const openForeignTermsSign = async () => {
		let object = await Preferences.get({
			'key': 'foreign_terms_sign',
		});

		let needForeignTermsSign = null;
		if (object.value === null) {
			needForeignTermsSign = await hasForeignTermsSignContract();

			await Preferences.set({
				'key': 'foreign_terms_sign',
				'value': needForeignTermsSign ? '1' : '0'
			});
		} else {
			needForeignTermsSign = object.value === '1';
		}

		if (needForeignTermsSign) {
			updateShowForeignTermsSignModal(true);
		}
	}

	useEffect(() => {
		fetchMenu();
		getAvatarUrl();
		redirectToMember();
		openCompareVersions();
		openTisax();
		openForeignTermsSign();
	}, []);

	useEffect(() => {
		checkTokenExpiration();

		const interval = setInterval(checkTokenExpiration, 30000);
		return () => clearInterval(interval);
	}, []);

	const checkTokenExpiration = async () => {
		let expires = await Preferences.get({
			'key': 'expires_in',
		});

		if (expires.value) {
			let now = moment();
			let expiresAt = moment(expires.value, 'unix');
			let expiresAtMod = moment(expires.value, 'unix').subtract(120, 'seconds');

			if (!showTokenExpirationToast) {
				if (now.isAfter(expiresAt)) {
					await logout().then(() => {
						auth.signOut();
						history.replace(Links.autoLoggedOut)
						window.location.reload();
					});
				}

				if (now.isAfter(expiresAtMod) && expiresAtMod.diff(now, 'seconds') < 120) {
					await setTokenExpirationToastDuration(expiresAt.diff(now, 'ms'));
					setShowTokenExpirationToast(true);
				}
			}
		}
	}

	useIonViewWillEnter(() => {
		checkTokenExpiration();
	})

	const showPhoneModalHandle = async () => {
		let hasPhoneNumber = null;

		await hasPhone().then(value => {
			hasPhoneNumber = value;
		});

		if (hasPhoneNumber === false) {
			updateShowPhoneModal(true);
		}
	}

	useIonViewDidEnter(() => {
		showPhoneModalHandle().then(() => {
			openTisax();
		});
	});

	// useEffect(() => {
	// 	redirectToTerms();
	// });

	const [showNotification, setShowNotification] = useState<any>(false);

	const contextMainPage = useContext(MainPageContext);
	contextMainPage.setAvatarUrl = setAvatarUrl;
	contextMainPage.setShowTokenExpirationToast = setShowTokenExpirationToast;
	contextMainPage.setNotificationBadge = setShowNotification;
	contextMainPage.setMenuItems = setMenuItems;
	contextMainPage.getNotificationBadge = () => {
		return showNotification;
	};

	const fetchUnreadNotifications = async () => {
		let unread = await hasUnreadNotifications();
		setShowNotification(unread);
	}

	useEffect(() => {
		fetchUnreadNotifications();
	}, []);

	const handleTabButtonClick = (name: string) => {
		log('page_visit', {
			page: name
		});
	};

	const [tisaxSignModalConfiguration, updateTisaxSignModalConfiguration] = useState<ISignModalConfigration>();
	const [foreignTermsSignModalConfiguration, updateForeignTermsSignModalConfiguration] = useState<ISignModalConfigration>();
	const [showTisaxSignModal, updateShowTisaxSignModal] = useState<boolean>(false);
	const [showForeignTermsSignModal, updateShowForeignTermsSignModal] = useState<boolean>(false);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toast, setToast] = useState<string>('');

	const tisaxSign = async (rulesAccepted: boolean, tisaxSignedByWorker: boolean) => {
		await tisaxSave(rulesAccepted, tisaxSignedByWorker).then(() => {
			updateTisaxSignModalConfiguration({
				title: t("tisax.sign.title"),
				info: t("tisax.sign.info"),
				buttonText: t("tisax.sign.signButton")
			});

			updateShowTisaxSignModal(true);
		}).catch(async () => {
			await Preferences.remove({
				'key': 'tisax_need_sign'
			});
		})
		updateShowTisaxModal(false);
	};

	const tisaxVerify = async (code: string) => {
		await tisaxSaveSign(code).then(async () => {
			updateShowTisaxSignModal(false);

			await Preferences.set({
				'key': 'tisax_need_sign',
				'value': '0'
			});
		}).catch(async (error) => {
			await Preferences.remove({
				'key': 'tisax_need_sign'
			});
			if (error.response && error.response.status === 400) {
				setToast(t("common.invalidVerifyCode"));
				setShowToast(true);
			}
		});
	}

	const foreignTermsSign = async (rulesAccepted: boolean) => {
		await foreignTermsSave(rulesAccepted).then(() => {
			updateForeignTermsSignModalConfiguration({
				title: t("foreignTerms.sign.title"),
				info: t("foreignTerms.sign.info"),
				buttonText: t("foreignTerms.sign.signButton")
			});

			updateShowForeignTermsSignModal(true);
		}).catch(async () => {
			await Preferences.remove({
				'key': 'foreign_terms_sign'
			});
		})
		updateShowForeignTermsModal(false);
	};

	const foreignTermsSignVerify = async (code: string) => {
		await foreignTermsSaveSign(code).then(async () => {
			updateShowForeignTermsSignModal(false);

			await Preferences.set({
				'key': 'foreign_terms_sign',
				'value': '0'
			});
		}).catch(async (error) => {
			await Preferences.remove({
				'key': 'foreign_terms_sign'
			});
			if (error.response && error.response.status === 400) {
				setToast(t("common.invalidVerifyCode"));
				setShowToast(true);
			}
		});
	}

    return (
		<MainPageContext.Provider value={contextMainPage}>
			<StyledIonTabs>
				<IonRouterOutlet id="tabs">
					<Route path={Links.main} render={() => <Redirect to={Links.main + Links.reports} />} exact={true} />
					<Route path={Links.main + Links.reports} component={ReportsTab} exact={true} />
					<Route path={Links.main + Links.offers} component={OffersTab} exact={true} />
					<Route path={Links.main + Links.communicator} component={CommunicatorTab} exact={true} />
					<Route path={Links.main + Links.notifications} component={NotificationsTab} exact={true} />
					<Route path={Links.main + Links.planning} component={PlanningTab} exact={true} />
					<Route path={Links.main + Links.profile} component={ProfileTab} exact={true} />
					<Route path={Links.main + Links.settings} component={SettingsTab} exact={true} />
					<Route path={Links.main + Links.language} component={LanguageTab} exact={true} />
					<Route path={Links.main + Links.cities} component={CitiesTab} exact={true} />
					<Route path={Links.main + Links.timetable} component={TimetablePage} exact={true} />
					<Route path={Links.main + Links.contracts} component={ContractsPage} exact={true} />
					<Route path={Links.main + Links.referFriends} component={ReferFriendsPage} exact={true} />
					<Route path={Links.main + Links.availability} component={AvailabilityPage} exact={true} />
					<Route path={Links.main + Links.wallet} component={WalletPage} exact={true} />
					<Route path={Links.main + Links.hoursRegistry} component={HoursRegistryPage} exact={true} />
					<Route path={Links.main + Links.loans} component={LoansPage} exact={true} />
					<Route path={Links.main + Links.myData} component={MyDataPage} exact={true} />
					<Route path={Links.main + Links.billings} component={BillingPage} exact={true} />
					<Route path={Links.main + Links.myData} component={MyDataPage} exact={true} />
					<Route path={Links.main + Links.hoursWorked} component={HoursWorkedPage} exact={true} />
					<Route path={Links.main + Links.workerWarehouse} component={WorkerWarehousePage} exact={true} />
					<Route path={Links.main + Links.recruitment + '/DEFAULT'} component={RecruitmentPage} exact={true} />
					<Route path={Links.main + Links.audits} component={AuditReports} exact={true} />
					<Route path={Links.main + Links.inconsistencyModule} component={InconsistencyModulePage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.menu} component={SubMenuPage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.rideRoute.list} component={RideRouteListPage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.rideRoute.add} component={RideRouteAddPage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.rideRoute.edit + '/:rideRouteId'} render={(props => {
						return <RideRouteEditPage {...props}/>
					})} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.car.list} component={CarListPage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.car.add} component={CarAddPage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.car.edit + '/:carId'} render={(props => {
						return <CarEditPage {...props}/>
					})} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.ride.list} component={RideListPage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.ride.add} component={RideAddPage} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.ride.edit + '/:rideId'} render={(props => {
						return <RideEditPage {...props}/>
					})} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.ride.preview + '/:rideId'} render={(props => {
						return <RidePreviewPage {...props}/>
					})} exact={true}/>
					<Route path={Links.main + Links.travelAllowance.rideReport.list} component={RideReportListPage} exact={true}/>
				</IonRouterOutlet>
				<IonTabBar slot="bottom" id="app-tab-bar" >
					{
						menuItems.length > 0 && menuItems.slice(0,4).map((item, key) => {
							if (item.reference === 'app.mobile_menu.reports') {
								return <IonTabButton key={key} tab="reports" href={Links.main + Links.reports}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Raporty')}>
										<IonIcon src={ReportsImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={ReportsImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("reportsTab.title")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.reports_ko') {
								return <IonTabButton key={key} tab="reports" href={Links.main + Links.reports}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Raporty')}>
										<IonIcon src={ReportsImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={ReportsImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.reports_ko")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.offers') {
								return <IonTabButton key={key} tab="offers" href={Links.main + Links.offers}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Oferty')}>
										<IonIcon src={JobOffersImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={JobOffersImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.offers")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}
					// {isCoordinator !== '1' && <IonTabButton tab="offers" href={Links.main + Links.offers}>
					// 		<img className="non-active" src={UserImage}/>
					// 		<img className="active" src={UserActiveImage}/>
					// 		<IonLabel>{t("offersTab.title")}</IonLabel>
					// 	</IonTabButton>
					// }
					//
					// {isCoordinator === '1' && <IonTabButton tab="planning" href={Links.main + Links.planning}>
					// 		<img className="non-active" src={PlanningImage} />
					// 		<img className="active" src={PlanningActiveImage} />
					// 		<IonLabel>{t("planningTab.title")}</IonLabel>
					// 	</IonTabButton>
					// }

							if (item.reference === 'app.mobile_menu.notifications') {
								return <IonTabButton key={key} tab="notifications" href={Links.main + Links.notifications}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Powiadomienia')}>
										<IonIcon src={NotificationsImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={NotificationsImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										{contextMainPage.getNotificationBadge() && <StyledNewNotification></StyledNewNotification>}
										<IonLabel>{t("app.mobile_menu.notifications")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.profile') {
								return <IonTabButton key={key} tab="profile" href={Links.main + Links.profile}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Profil')}>
										<img src={avatarUrl || AvatarImage} className="profile"/>
										<IonLabel>{t("app.mobile_menu.profile")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.availability') {
								return <IonTabButton key={key} tab="availability" href={Links.main + Links.availability}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Dostępność')}>
										<IonIcon src={AvailabilityImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={AvailabilityImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.availability")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.schedule') {
								return <IonTabButton key={key} tab="schedule" href={Links.main + Links.timetable}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Harmonogram')}>
										<IonIcon src={ScheduleImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={ScheduleImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.schedule")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.wallet') {
								return <IonTabButton key={key} tab="wallet" href={Links.main + Links.reports}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Portfel')}>
										<IonIcon src={WalletImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={WalletImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.wallet")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.contracts') {
								return <IonTabButton key={key} tab="contracts" href={Links.main + Links.contracts}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Umowy')}>
										<IonIcon src={ContractsImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={ContractsImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.contracts")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.planning') {
								return <IonTabButton key={key} tab="planning" href={Links.main + Links.planning}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Planowanie')}>
										<IonIcon src={PlanningImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={PlanningImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.planning")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.hours_register') {
								return <IonTabButton key={key} tab="hours_register" href={Links.main + Links.profile}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Rejestr godzin')}>
										<IonIcon className="non-active" src={HoursRegistryImage} />
										<IonIcon className="active" src={HoursRegistryImageActive} />
										<IonLabel>{t("app.mobile_menu.hours_register")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.exact_pay') {
								return <IonTabButton key={key} tab="exact_pay" href={Links.main + Links.loans}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Exact Pay')}>
										<IonIcon className="non-active" src={ExactPayImage} />
										<IonIcon className="active" src={ExactPayImageActive} />
										<IonLabel>{t("app.mobile_menu.exact_pay")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.payout_billings') {
								return <IonTabButton key={key} tab="billings" href={Links.main + Links.billings}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Billings')}>
										<IonIcon className="non-active" src={BillingsImage} />
										<IonIcon className="active" src={BillingsImageActive} />
										<IonLabel>{t("app.mobile_menu.payout_billings")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.hours_worked') {
								return <IonTabButton key={key} tab="wallet" href={Links.main + Links.hoursWorked}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Godziny')}>
										<IonIcon src={HoursWorkedImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={HoursWorkedImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.hours_worked")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.worker_warehouse') {
								return <IonTabButton key={key} tab="warehouse" href={Links.main + Links.workerWarehouse}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Wyposażenie BHP')}>
										<IonIcon src={bagOutline} style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonLabel>{t("app.mobile_menu.worker_warehouse")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.my_data') {
								return <IonTabButton key={key} tab="my_data" href={Links.main + Links.myData}>
									<StyledMenuButton style={{"display": "inherit"}} onClick={() => handleTabButtonClick('Moje dane')}>
										<IonIcon src={MyDataImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonIcon src={MyDataImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>
										<IonLabel>{t("app.mobile_menu.my_data")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.recruitment') {
								return <IonTabButton key={key} tab="recruitment" href={Links.main + Links.recruitment + '/DEFAULT'}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Rekrutacja')}>
										<IonIcon src={bagOutline} style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonLabel>{t("app.mobile_menu.recruitment")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.audit_reports') {
								return <IonTabButton key={key} tab="audits" href={Links.main + Links.audits}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Audyty')}>
										<IonIcon src={checkboxOutline} style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonLabel>{t("app.mobile_menu.audit_reports")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}

							if (item.reference === 'app.mobile_menu.audit_incompatibilities') {
								return <IonTabButton key={key} tab="incompatibilities" href={Links.main + Links.inconsistencyModule}>
									<StyledMenuButton onClick={() => handleTabButtonClick('Moduł niezgodności')}>
										<IonIcon src={checkboxOutline} style={{"fontSize": "20px", "color": '#888A98'}}/>
										<IonLabel>{t("app.mobile_menu.audit_incompatibilities")}</IonLabel>
									</StyledMenuButton>
								</IonTabButton>
							}
						})
					}

					{/*<IonTabButton tab="communicator" href={Links.main + Links.communicator}>*/}
					{/*	<img className="non-active" src={ChatImage} />*/}
					{/*	<img className="active" src={ChatActiveImage} />*/}
					{/*	<IonLabel>Komunikator</IonLabel>*/}
					{/*</IonTabButton>*/}

					{menuItems.length > 0 && <IonTabButton tab="menu">
						<StyledFullMenuButton className="moreMenu" onClick={(e) => {
							updateShowMenuModal(true)
						}}>
							<IonIcon src={MoreImage} style={{"fontSize": "20px", "color": '#888A98'}} /><br/>
							<IonLabel>{t("app.mobile_menu.more")}</IonLabel>
						</StyledFullMenuButton>
					</IonTabButton>
					}
				</IonTabBar>
			</StyledIonTabs>

			{/*<AppApprovalsModal withLogout={true} isOpen={showApprovals} onClose={() => updateShowApprovals(false)}></AppApprovalsModal>*/}
			<TeamMemberPhoneModal isOpen={showPhoneModal} onClose={() => updateShowPhoneModal(false)} onSubmit={ async (phone) => {
				updateShowPhoneModal(false)
				await Preferences.set({'key': 'phone', 'value': phone});
			}}/>
			{/*<AppUpdateModal isOpen={showAppUpdateModal} onClose={() => updateShowAppUpdateModal(false)} />*/}
			<TisaxModal isOpen={showTisaxModal} onClose={() => updateShowTisaxModal(false)} onSign={(rulesAccepted: boolean, tisaxSignedByWorker: boolean) => tisaxSign(rulesAccepted, tisaxSignedByWorker)} />
			<MenuModal isOpen={showMenuModal} onClose={() => updateShowMenuModal(false)} menuItems={menuItems} avatarUrl={avatarUrl} />
			<ForeignTermsSignModal isOpen={showForeignTermsSignModal} onClose={() => updateShowForeignTermsSignModal(false)} onSign={(rulesAccepted: boolean) => foreignTermsSign(rulesAccepted)} />
			{tisaxSignModalConfiguration && <SignModal configuration={tisaxSignModalConfiguration}
														   isOpen={showTisaxSignModal}
														   onClose={() => updateShowTisaxSignModal(false)}
														   onSave={(code: string) => tisaxVerify(code)}>

			</SignModal>}

			{foreignTermsSignModalConfiguration && <SignModal configuration={foreignTermsSignModalConfiguration}
														   isOpen={showForeignTermsSignModal}
														   onClose={() => updateShowForeignTermsSignModal(false)}
														   onSave={(code: string) => foreignTermsSignVerify(code)}>

			</SignModal>}
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message={toast}
				duration={6000}
				position="top"
				color="danger"
			/>

			{showTokenExpirationToast && <IonToast
				isOpen={showTokenExpirationToast}
				onDidDismiss={() => setShowTokenExpirationToast(false)}
				message={t('app.common.tokenExpirationMessage')}
				duration={tokenExpirationToastDuration}
				position="top"
				color="warning"
			/>}
		</MainPageContext.Provider>
	);
};

export default MainPage;
