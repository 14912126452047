import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {
    IonApp,
    IonLoading,
    IonRouterOutlet,
    IonToast,
    isPlatform,
    mdTransitionAnimation,
    setupIonicReact,
    useIonViewWillEnter,
    useIonViewDidEnter,
	getPlatforms
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import GlobalStyle from './utils/styles/GlobalStyle';
import {Links} from "./app/links";
import Login from './app/login/login.component';
import ForgotPassword from './app/forgotPassword/forgotPassword.component';
import ForgotPasswordSuccess from './app/forgotPassword/forgotPasswordSuccess.component';
import CreateAccount from './app/createAccount/createAccount.component';
import MainPage, {MainPageContext} from './app/mainPage/mainPage.component';
import ContractPage from './app/contractPage/contractPage.component';
import AddEditValuePage from './app/addEditValuePage/addEditValuePage.component';
import AddEditDatePage from './app/addEditDatePage/addEditDatePage.component';
import AddEditCityPage from './app/addEditCityPage/addEditCityPage.component';
import AddEditPhonePage from './app/addEditPhonePage/addEditPhonePage.component';
import TakePhotoPage from './app/takePhotoPage/takePhotoPage.component';
import StatusPage from './app/statusPage/statusPage.component';
import UserRemovePage from './app/user/userRemovePage.component';
import EditDailyAvailabilityPage from './app/editDailyAvailabilityPage/editDailyAvailabilityPage.component';
import AppModeTab from './app/mainPage/tabs/appMode/appModeTab.component';
import ContractDataPage from './app/contractDataPage/contractDataPage.component';
import BecameTeamMemberPage from './app/becameTeamMemberPage/becameTeamMemberPage.component';
import ReferFriendsPage from './app/referFriendsPage/referFriendsPage.component';
import TeamMemberPage from './app/teamMemberPage/teamMemberPage.component';
import CoursesPage from "./app/coursesPage/coursesPage.component";
import LanguagesPage from "./app/languagesPage/languagesPage.component";
import FolderPage from './app/folderPage/folderPage.component';
import AbsencePage from "./app/absencePage/absencePage.component";
import AppUrlListener from "./listeners/appUrlListener";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import './app/App.css';
import {inProgress} from './services/api.service';
import {
    logout,
    refreshAccessToken,
    storeToken,
    useProvideAuth,
    UserContext
} from "./services/auth.service";
import {appStatusBarUpdate} from "./utils/tools/statusbar";
import {Capacitor} from "@capacitor/core";
import {PushNotifications, PushNotification, PushNotificationActionPerformed} from "@capacitor/push-notifications";
import {AppLauncher} from '@capacitor/app-launcher';
import {App as AppPlugin} from '@capacitor/app';
import {Preferences} from "@capacitor/preferences";

/** import locales for moment.js **/
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ro';
import 'moment/locale/es';

import {arrowForwardCircleOutline} from "ionicons/icons";
import OfferPage from './app/offerPage/offerPage.component';
import ModalService from './services/modal.service';
import SendPushMessagePage from './app/sendPushMessagePage/sendPushMessagePage.component';
import ConversationPage from './app/conversationPage/conversationPage.component';
import OffersTab from "./app/mainPage/tabs/offers/offersTab.component";

import {initializeFirebase} from "./services/firebaseAnalytics.service";
import ContractAppendixPage from "./app/contractPage/contractAppendixPage.component";
import SelectPeoplePage from "./app/mainPage/tabs/planning/selectPeoplePage.component";
import AppUpdatePage from "./app/appUpdatePage/appUpdate.component";
import {getAppId} from "./variables/variables";
import {useTranslation} from "react-i18next";
import AddEditSizePage from "./app/addEditSizePage/addEditSizePage.component";
import ManualBarcodePage from './app/reportPage/manualBarcodePage.component';
import ReportPage from './app/reportPage/reportPage.component';
import QRCodePage from './app/reportPage/qrCodePage.component';
import ChoosePersonPage from './app/reportPage/choosePersonPage.component';
import QRCodeWorkerPage from "./app/reportPage/qrCodeWorkerPage.component";
import ChoosePersonExtendedPage from "./app/reportPage/choosePersonExtendedPage.component";
import LoanPage from "./app/loanPage/loanPage.component";
import BillingPage from "./app/billingPage/billingPage.component";
import moment from "moment";
import {AuthResult} from "./types/auth-result";
import ManualOrderFindPage from "./app/reportPage/manualOrderFindPage.component";
import MyDataPage from "./app/myData/myDataPage.component";
import ManualContainerFindPage from "./app/reportPage/manualContainerFindPage.component";
import HoursWorkedPage from "./app/hoursWorkedPage/hoursWorkedPage.component";
import {getMenu} from "./services/menu.service";
import AutoLoggedOut from "./app/logout/autoLoggedOut.component";
import WorkerWarehousePage from "./app/workerWarehouse/workerWarehousePage.component";
import RecruitmentPage from "./app/recruitmentPage/recruitmentPage.component";
import {Toast} from "@capacitor/toast";
import {Device} from "@capacitor/device";

import ChangePassword from "./app/changePassword/changePassword.component";
import AuditReportComponent from "./app/auditReport/auditReport.component";
import AuditReportStart from "./app/auditReport/start.component";
import AuditReportManualOrderFind from "./app/auditReport/manualOrderFind.component";
import AuditReportManualSpecificationFind from "./app/auditReport/manualSpecificationFind.component";
import AuditFindWorker from "./app/auditReport/findWorker.component";
import AuditManualWorkerFind from "./app/auditReport/manualWorkerFind.component";
import InconsistencyDetailsPage from "./app/inconsistencyModulePage/inconcistencyDetailsPage.component";
import InconsistencyEditPage from "./app/inconsistencyModulePage/inconcistencyEditPage.component";
import RideReportPreviewPage from "@app/travelAllowance/rideReport/pages/rideReportPreviewPage.component";
import StatusConnectionModal from "./modals/status/statusConnection.component";
import QRCodeContainerPage from "@app/reportPage/qrCodeContainerPage.component";

const App: React.FC = () => {
    const auth = useProvideAuth();
    const [showNotificationToast, setShowNotificationToast] = useState(false);
    const [notificationToast, setNotificationToast] = useState<any>('');
    const [showLoading, updateShowLoading] = useState(false);

    const mainPageManager = useContext(MainPageContext);

    const push = () => {
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotification) => {
                setNotificationToast(notification.title);
                setShowNotificationToast(true);
                mainPageManager.setNotificationBadge(true);
            }
        );

        // Some issue with your setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: PushNotificationActionPerformed) => {
                AppLauncher.openUrl({url: getAppId() + ':/' + Links.main + Links.notifications})
            }
        );
    }

    useIonViewWillEnter(() => {
        appStatusBarUpdate();
    })

    setupIonicReact({
        mode: 'ios',
        animated: true,
        swipeBackEnabled: true,
        keyboardHeight: isPlatform('ios') ? 0 : undefined,
        navAnimation: mdTransitionAnimation,
    });

    const initLogout = async () => {
        await logout().then(() => {
            auth.signOut();
            window.history.replaceState({}, '', Links.login);
        });
    };

    useEffect(() => {
        const interval = setInterval(checkLoader); // Without time will be executed when resources are available;
        return (() => {
            clearInterval(interval)
        });
    }, []);

    useEffect(() => {
        initLogout();
    }, []);

    let lastTimeBackPress: number = 0;
    let timePeriodToExit: number = 2000;

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            AppPlugin.addListener("backButton", (e) => {

                if (ModalService.isModalOpen()) {
                    return;
                }

                if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
                    AppPlugin.exitApp();
                }

                if (window.location.pathname === Links.main + Links.reports ||
                    window.location.pathname === Links.root ||
                    window.location.pathname === Links.main + Links.availability ||
                    window.location.pathname === Links.main + Links.profile) {

                    if (!e.canGoBack) {
                        lastTimeBackPress = new Date().getTime();

                        Toast.show({
                            text: t('common.appExitConfirmation'),
                            duration: 'short',
                            position: 'bottom',
                        })
                    }
                } else if (window.location.pathname === Links.login) {
                    AppPlugin.exitApp();
                }
            });

            AppPlugin.addListener("appStateChange", (e) => {
                if (e.isActive) {
                    autoTokenRefresh(true);
                }
            });
        }

        push();
        initializeFirebase();
    }, []);

    let changeInterval: number | undefined = undefined;
    const checkLoader = () => {
        if (inProgress() !== showLoading && changeInterval === undefined) {
            changeInterval = window.setTimeout(() => {
                changeInterval = undefined;
                updateShowLoading(inProgress());
            }, 400);
        }
    }

    useEffect(() => {
        const interval = setInterval(refreshTokenOnCountClicks, 30000);
        return () => clearInterval(interval);
    }, []);

    const reloadMenu = async () => {
        await getMenu().then(response => {
            mainPageManager.setMenuItems(response.data);
        });
    };

    const refreshTokenOnCountClicks = async () => {
        let countLastRefreshClicks = await Preferences.get({
            'key': 'countLastRefreshClicks',
        });
        let expires = await Preferences.get({
            'key': 'expires_in',
        });

        if (expires.value) {
            updateCountLastRefreshClicks(0);
            if (countLastRefreshClicks.value && parseInt(countLastRefreshClicks.value) > 0) {
                refreshAccessToken()
                    .then(tokenRefreshResponse => {
                        let authResult = tokenRefreshResponse.data as AuthResult;
                        authResult.is_fb_user = undefined;
                        storeToken(authResult);
                        mainPageManager.setShowTokenExpirationToast(false);
                    }).catch(async (reason) => {
                    // mainPageManager.setShowTokenExpirationToast(false);
                    // await logout().then(() => {
                    //     auth.signOut();
                    //     window.history.replaceState({}, '', Links.autoLoggedOut);
                    // });
                })
            }
        }
    };

    const updateCountLastRefreshClicks = async (clicks: number) => {
        await Preferences.set({
            'key': 'countLastRefreshClicks',
            'value': clicks.toString(),
        });
    }

    const {t} = useTranslation();

    const autoTokenRefresh = async (autoLogout: boolean = false) => {
        let expires = await Preferences.get({
            'key': 'expires_in',
        });

        if (expires.value) {
            let now = moment();
            let expiresAt = moment(expires.value, 'unix');
            let expiresAtModified = moment(expires.value, 'unix').subtract(120, 'seconds');

            if (!autoLogout && now.isAfter(expiresAtModified)) {
                refreshAccessToken()
                    .then(tokenRefreshResponse => {
                        let authResult = tokenRefreshResponse.data as AuthResult;
                        authResult.is_fb_user = undefined;
                        storeToken(authResult);
                        mainPageManager.setShowTokenExpirationToast(false);
                    }).catch(async (reason) => {
                    mainPageManager.setShowTokenExpirationToast(false);
                    await logout().then(() => {
                        auth.signOut();
                        window.history.replaceState({}, '', Links.autoLoggedOut);
                    });
                })
            }

            if (autoLogout && now.isAfter(expiresAt)) {
                mainPageManager.setShowTokenExpirationToast(false);
                await logout().then(() => {
                    auth.signOut();
                    window.history.replaceState({}, '', Links.autoLoggedOut);
                });
            }
        }
    }

    return (<>
        <StatusConnectionModal/>

        <IonToast
            isOpen={showNotificationToast}
            onDidDismiss={() => setShowNotificationToast(false)}
            message={notificationToast}
            duration={10000}
            position="top"
            color="warning"
            buttons={[
                {
                    side: 'end',
                    icon: arrowForwardCircleOutline,
                    handler: () => {
                        AppLauncher.openUrl({url: getAppId() + ':/' + Links.main + Links.notifications})
                    }
                }
            ]}
        />

        <GlobalStyle/>
        <UserContext.Provider value={auth}>
            <IonApp>
                {/*<IonReactRouter>*/}
                {/*    <IonRouterOutlet>*/}
                {/*        <Route path={Links.login} component={Login} exact={true}/>*/}
                {/*    </IonRouterOutlet>*/}
                {/*</IonReactRouter>*/}
                {!auth.isAuthenticated ? (
                    <IonReactRouter>
                        <IonRouterOutlet>
                            <Route component={Login}/>
                            <Route path={Links.root} component={Login} exact={true}/>
                            <Route path={Links.login} component={Login} exact={true}/>
                            <Route path={Links.forgotPassword} component={ForgotPassword} exact={true}/>
                            <Route path={Links.forgotPasswordSuccess} component={ForgotPasswordSuccess} exact={true}/>
                            <Route path={Links.createAccount} component={CreateAccount} exact={true}/>
                            <Route path={Links.offer + '/:offerId'} render={(props => {
                                return <OfferPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.main + Links.offersOpen} component={OffersTab} exact={true}/>
                            <Route path={Links.autoLoggedOut} component={AutoLoggedOut} exact={true}/>
                        </IonRouterOutlet>
                    </IonReactRouter>
                ) : (
                    <IonReactRouter>
                        <AppUrlListener/>
                        <IonRouterOutlet onClick={() => {
                            updateCountLastRefreshClicks(1);
                            autoTokenRefresh();
                        }}>
                            <Route path={Links.main} component={MainPage} exact={false}/>
                            <Route path={Links.contract + '/:contractId'} render={(props => {
                                return <ContractPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.contract + '/:contractId' + Links.folder + '/:folderId'}
                                   render={(props => {
                                       return <FolderPage {...props}/>
                                   })} exact={true}/>
                            <Route path={Links.contractAppendix + '/:appendixId'} render={(props => {
                                return <ContractAppendixPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.loan + '/:loanId'} render={(props => {
                                return <LoanPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.offer + '/:offerId'} render={(props => {
                                return <OfferPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.contractData} component={ContractDataPage} sensitive={true}
                                   exact={true}/>
                            <Route path={Links.changePassword} component={ChangePassword} sensitive={true}
                                   exact={true}/>
                            <Route path={Links.becameTeamMember} component={BecameTeamMemberPage} exact={true}/>
                            <Route path={Links.teamMember} component={TeamMemberPage} exact={true}/>
                            <Route path={Links.courses} component={CoursesPage} exact={true}/>
                            <Route path={Links.languages} component={LanguagesPage} exact={true}/>
                            <Route path={Links.absences} component={AbsencePage} exact={true}/>
                            <Route path={Links.addEditValue} component={AddEditValuePage} exact={true}/>
                            <Route path={Links.addEditSize} component={AddEditSizePage} exact={true}/>
                            <Route path={Links.editDailyAvailability} component={EditDailyAvailabilityPage}
                                   exact={true}/>
                            <Route path={Links.status} component={StatusPage} exact={true}/>
                            <Route path={Links.birthDate} component={AddEditDatePage} exact={true}/>
                            <Route path={Links.city} component={AddEditCityPage} exact={true}/>
                            <Route path={Links.phone} component={AddEditPhonePage} exact={true}/>
                            <Route path={Links.sendPushMessage} component={SendPushMessagePage} exact={true}/>
                            <Route path={Links.takePhoto} component={TakePhotoPage} exact={true}/>
                            <Route path={Links.qrCodeWorker} component={QRCodeWorkerPage} exact={true}/>
                            <Route path={Links.qrCodeSpecification} component={QRCodePage} exact={true}/>
                            <Route path={Links.qrCodeContainer + '/:orderId/:specificationId'}
                                   render={(props => {
                                       return <QRCodeContainerPage {...props}/>
                                   })} exact={true}/>
                            <Route path={Links.conversationPage} component={ConversationPage} exact={true}/>
                            <Route path={Links.appMode} component={AppModeTab} exact={true}/>
                            <Route path={Links.manualBarcode} component={ManualBarcodePage} exact={true}/>
                            <Route path={Links.choosePerson} component={ChoosePersonPage} exact={true}/>
                            <Route path={Links.report} component={ReportPage} exact={true}/>
                            <Route path={Links.selectPeople + '/:data'} render={(props => {
                                return <SelectPeoplePage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.appUpdate} component={AppUpdatePage} exact={true}/>
                            <Route path={Links.manualBarcode} component={ManualBarcodePage} exact={true}/>
                            <Route path={Links.manualOrderFind} component={ManualOrderFindPage} exact={true}/>
                            <Route path={Links.manualContainerFind + '/:orderId/:specificationId'}
                                   render={(props => {
                                       return <ManualContainerFindPage {...props}/>
                                   })} exact={true}/>
                            <Route path={Links.choosePerson + '/:orderId/:serviceDate'} render={(props => {
                                return <ChoosePersonPage {...props}/>
                            })} exact={true}/>
                            <Route
                                path={Links.choosePersonExtended + '/:orderId/:specificationId/:serviceDate/:reportId'}
                                render={(props => {
                                    return <ChoosePersonExtendedPage {...props}/>
                                })} exact={true}/>
                            <Route path={Links.report + '/:orderId/:specificationId/:reportId/:reportPreview/:containerId'}
                                   render={(props => {
                                       return <ReportPage {...props}/>
                                   })} exact={true}/>
                            <Route path={Links.root} render={(props) =>
                                <Redirect to={Links.main + Links.profile}/>
                            } exact={true}/>
                            <Route path={Links.billings} component={BillingPage} exact={true}/>
                            <Route path={Links.myData} component={MyDataPage} exact={true}/>
                            <Route path={Links.hoursWorked} component={HoursWorkedPage} exact={true}/>
                            <Route path={Links.autoLoggedOut} component={AutoLoggedOut} exact={true}/>
                            <Route path={Links.userRemove} component={UserRemovePage} exact={true}/>
                            <Route path={Links.workerWarehouse} component={WorkerWarehousePage} exact={true}/>
                            <Route path={Links.referFriends} component={ReferFriendsPage} exact={true}/>
                            <Route path={Links.recruitment + '/:hash'} render={(props => {
                                return <RecruitmentPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.audit + '/:type/:orderId/:specificationId'} render={(props => {
                                return <AuditReportComponent {...props}/>
                            })} exact={true}/>
                            <Route path={Links.auditStart + '/:type'} render={(props => {
                                return <AuditReportStart {...props}/>
                            })} exact={true}/>
                            <Route path={Links.auditManualOrderFind + '/:type'} render={(props => {
                                return <AuditReportManualOrderFind {...props}/>
                            })} exact={true}/>
                            <Route path={Links.auditManualSpecificationFind + '/:type'} render={(props => {
                                return <AuditReportManualSpecificationFind {...props}/>
                            })} exact={true}/>
                            <Route path={Links.auditWorkerFind} component={AuditFindWorker} exact={true}/>
                            <Route path={Links.auditManualWorkerFind} component={AuditManualWorkerFind} exact={true}/>
                            <Route path={Links.inconsistencyRecordPreview + '/:id'} render={(props => {
                                return <InconsistencyDetailsPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.inconsistencyRecordEdit + '/:id'} render={(props => {
                                return <InconsistencyEditPage {...props}/>
                            })} exact={true}/>
                            <Route path={Links.travelAllowance.rideReport.preview + '/:reportId'} render={(props => {
                                return <RideReportPreviewPage {...props}/>
                            })} exact={true}/>
                        </IonRouterOutlet>
                    </IonReactRouter>
                )}
                <IonLoading onDidDismiss={() => updateShowLoading(false)} isOpen={showLoading} duration={1000}/>
            </IonApp>
        </UserContext.Provider>
    </>);
};

export default App;
