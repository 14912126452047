import React, {useEffect, useState} from 'react';
import {IonAlert, IonIcon, IonLoading, IonToast} from "@ionic/react";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";

import {StyledButton, StyledRedButton, StyledSocialButton} from '@components/button/button.style';
import {StyledNoOrders, StyledOrders} from "@app/mainPage/tabs/reports/panes/reports.style";

import Pane from '@components/pane/pane.component';
import ReportsSkeleton from "@app/mainPage/tabs/reports/components/reportsSkeleton.component";

import ClockImage from '@assets/images/time-clock.svg';
import AddImage from "@assets/images/e-add.svg";
import NoOrdersImage from "@assets/images/no-orders.svg"
import ReportsImage from "@assets/images/chart-pie-36.svg";
import AvatarImage from "@assets/images/avatar.svg";
import ArrowRightImage from "@assets/images/arrow-right-black.svg";

import {Links} from "@app/links";

import {RideReport} from "@models/travelAllowance/rideReport";

import {getRideReports, sendToAssecoRideReport} from "@services/travelAllowance/rideReport.service";
import {getRideMatrixByRideReportId} from "@services/travelAllowance/rideMatrix.service";
import {RideMatrix} from "@models/travelAllowance/rideMatrix";

type RideReportListPaneProps = {
    topEdge?: number;
}

const RideReportListPane: React.FC<RideReportListPaneProps> = ({topEdge}: RideReportListPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showAcceptConfirmationAlert, setShowAcceptConfirmationAlert] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const [selectedRideReportId, setSelectedRideReportId] = useState<number>();
    const [reports, setReports] = useState<RideReport[]>([]);

    useEffect(() => {
        setLoading(true);

        fetchRideReports()
            .then(() => {
                setLoading(false);
            });
    }, [history.location.pathname, refresh]);

    const fetchRideReports = async () => {
        const rideReportsData = await getRideReports();

        setReports(rideReportsData);
    }

    const handleReportSelect = async (reportId: number) => {
        setShowLoader(true);

        getRideMatrixByRideReportId(reportId)
            .then((rideMatrixData: RideMatrix) => {
                if (!rideMatrixData.isRideContract && !rideMatrixData.isTravelOrder && !rideMatrixData.isRideReport) {
                    setSelectedRideReportId(reportId);
                    setShowAcceptConfirmationAlert(true);
                } else {
                    history.push(Links.travelAllowance.rideReport.preview + '/' + reportId);
                }
            })
            .finally(() => {
                setShowLoader(false);
            })
    }

    const handleAcceptRideReport = (reportId: number) => {
        sendToAssecoRideReport(reportId)
            .then(() => setRefresh(true))
            .catch((error) => {
                setToast(t("travelAllowance.rideReport.acceptError"));
                setShowToast(true);

                console.error(error);
            });
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonAlert
                isOpen={showAcceptConfirmationAlert}
                onDidDismiss={() => setShowAcceptConfirmationAlert(false)}
                header={t('travelAllowance.rideReport.acceptConfirmationAlert.title')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAcceptConfirmationAlert(false);
                            setSelectedRideReportId(undefined);
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            if (selectedRideReportId) {
                                handleAcceptRideReport(selectedRideReportId);
                            }
                        }
                    }
                ]}
            />
            <StyledSocialButton className="inverted" onClick={() => history.push(Links.main + Links.travelAllowance.ride.add)}>
                <img src={AddImage}/>
                {t('travelAllowance.ride.add')}
            </StyledSocialButton>
            {
                !loading && reports && reports.length === 0 &&
                <StyledNoOrders>
                    <img src={NoOrdersImage}/>
                    <img className="reports" src={ReportsImage}/>
                    <span className="message">{t('reportsTab.orders.noReports')}</span>
                    <StyledSocialButton className="auto-width" onClick={() => history.push(Links.main + Links.travelAllowance.ride.list)}>
                        {t('travelAllowance.ride.list')}
                    </StyledSocialButton>
                </StyledNoOrders>
            }
            {
                !loading && reports && reports.length !== 0 &&
                <StyledOrders>
                    {
                        reports.map((report: RideReport, i) => {
                                return (
                                    <div key={i} className="ride-report">
                                        <div className="ride-report-small">
                                            <IonIcon src={ClockImage}/>
                                            {t('travelAllowance.report.billingMonth')}&nbsp;{report.billingMonth}
                                        </div>
                                        <div className="ride-report-number">
                                            {t('travelAllowance.report.reportNumber')}&nbsp;{report.number}
                                            <img src={ArrowRightImage}/>
                                        </div>
                                        <div className="members"
                                             style={{
                                                 display: "flex",
                                                 alignItems: "center",
                                                 gap: "10px",
                                                 margin: "0",
                                                 marginTop: "16px"
                                             }}>
                                            <img src={AvatarImage}/>
                                            <span style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}>{report.company.name}</span>
                                        </div>
                                        {
                                            report.status === 'WAITING' &&
                                            <StyledButton onClick={() => handleReportSelect(report.id)}>
                                                {t('travelAllowance.report.sign')}
                                            </StyledButton>
                                        }
                                        {
                                            report.status === 'ACCEPTED' &&
                                            <StyledButton disabled={true}>
                                                {t('travelAllowance.report.status.accepted')}
                                            </StyledButton>
                                        }
                                        {
                                            report.status === 'REJECTED' &&
                                            <StyledRedButton>
                                                {t('travelAllowance.report.status.rejected')}
                                            </StyledRedButton>
                                        }
                                    </div>
                                )
                            }
                        )
                    }
                </StyledOrders>
            }
            {
                loading && <ReportsSkeleton/>
            }
        </Pane>
    );
};

export default RideReportListPane;
