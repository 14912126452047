import React, {useEffect, useState} from 'react';
import {IonCol, IonLoading, IonRow, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {useEvent} from "@hooks/useEvent";
import {useHistory} from "react-router-dom";
import axios from "axios";
import moment from "moment";

import Pane from "@components/pane/pane.component";
import Accordion from "@components/accordion/accordion";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import RideFormFields from "@app/travelAllowance/ride/components/rideFormFields.component";
import BackRideFormFields from "@app/travelAllowance/ride/components/backRideFormFields.component";
import CommentForCoordinatorModal from "@app/travelAllowance/ride/components/commentForCoordinatorModal";

import Form from "@components/form";

import {StyledButton, StyledTravelAllowanceContent, StyledWarningInfo} from "@app/travelAllowance/travelAllowance.style";
import {StyledInput} from "@components/form/input/input.style";

import {PlaceOfService, RideRoute} from "@models/travelAllowance/rideRoute";
import {Car} from "@models/travelAllowance/car";
import {
    BackRideForm, Coordinator,
    Passenger,
    PassengerFormField, Ride, RideChange,
    RideForm,
    RideFormData,
    RideFormDataForApi
} from "@models/travelAllowance/ride";

import {getRideRoutes} from "@services/travelAllowance/rideRoute.service";
import {getCars} from "@services/travelAllowance/car.service";
import {
    addRide,
    editRide,
    getPossiblePassengers
} from "@services/travelAllowance/ride.service";

import {ReactComponent as SaveIcon} from '@assets/images/travelAllowance/save.svg';
import {ReactComponent as WarningIcon} from '@assets/images/travelAllowance/warning.svg';

import {Links} from "@app/links";

import {RideFormContext} from "@context/rideForm.context";

import {RidePaneType} from "@enums/travelAllowance/ride";
import {EventType} from "@enums/eventType";

type RideAddEditPaneProps = {
    type: RidePaneType,
    topEdge?: number,
    rideDefaultValues?: Ride
    rideChanges?: RideChange
}

const RideAddEditPane: React.FC<RideAddEditPaneProps> = ({
                                                             type,
                                                             topEdge,
                                                             rideDefaultValues,
                                                             rideChanges
                                                         }: RideAddEditPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const {t} = useTranslation();
    const history = useHistory();
    const {dispatch} = useEvent();
    const form = useForm<RideFormData>({
        mode: 'onChange',
        defaultValues: {
            ride: {
                dateStart: rideDefaultValues?.dateStart || moment().format(),
                car: rideDefaultValues?.car.id,
                route: {
                    id: rideDefaultValues?.rideRoute.id,
                    startingPlace: rideDefaultValues?.rideRoute.startingPlace,
                    destinationPlace: rideDefaultValues?.rideRoute.destinationPlace,
                    startingPlaceCountry: rideDefaultValues?.rideRoute.startingPlaceCountry,
                    destinationPlaceCountry: rideDefaultValues?.rideRoute.destinationPlaceCountry,
                    distance: rideDefaultValues?.rideRoute.distance,
                    coordinator: rideDefaultValues?.rideRoute.coordinator.id,
                    placeOfService: rideDefaultValues?.rideRoute.placeOfService.id,
                    isLocal: rideDefaultValues?.rideRoute.isLocal,
                },
            },
        },
    });
    const watch = useWatch({
        control: form.control, name: [
            'ride.route',
            'ride.startingPlace',
            'ride.destinationPlace',
            'ride.startingPlaceCountry',
            'ride.destinationPlaceCountry',
            'ride.distance',
            'ride.placeOfService',
            'ride.car',
            'ride.coordinator',
            'ride.passengers'
        ]
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [rideRoutes, setRideRoutes] = useState<RideRoute[]>([]);
    const [cars, setCars] = useState<Car[]>([]);
    const [possiblePassengers, setPossiblePassengers] = useState<Passenger[]>([]);
    const [coordinator, setCoordinator] = useState<Coordinator | undefined>();
    const [placeOfService, setPlaceOfService] = useState<PlaceOfService | undefined>();

    const [isCommentForCoordinatorModalOpen, setIsCommentForCoordinatorModalOpen] = useState(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);

        Promise.all([fetchRideRoutes(), fetchCars(), fetchPossiblePassengers()])
            .then(() => {
                setLoading(false)
            })
            .catch((error) => {
                setToast(t("travelAllowance.ride.formLoadError"));
                setShowToast(true);

                console.error(error);
            })

        return () => {
            cancelToken.cancel();
        };
    }, []);

    useEffect(() => {
        dispatch(EventType['REFRESH.BACK_RIDE_FORM_FIELDS']);
    }, [watch]);

    const fetchRideRoutes = async () => {
        const rideRoutesData = await getRideRoutes(cancelToken);

        setRideRoutes(rideRoutesData);
    }

    const fetchCars = async () => {
        const carsData = await getCars(cancelToken);

        setCars(carsData);
    }

    const fetchPossiblePassengers = async () => {
        if (coordinator && placeOfService) {
            const passengersData = await getPossiblePassengers(coordinator.id, placeOfService.id, form.getValues().ride?.dateStart, cancelToken);

            setPossiblePassengers(passengersData);
        }
    }

    const onSubmit = ({ride, backRide, comment}: RideFormData) => {
        setShowLoader(true);

        const rideDataForApi = prepareRideOnSubmit(ride);
        const backRideDataForApi = prepareBackRideOnSubmit(backRide);

        const data: RideFormDataForApi = {
            ...rideDataForApi,
            ...(backRideDataForApi && {backRide: backRideDataForApi}),
            ...(comment && {comment: comment}),
        };

        if (type === RidePaneType.ADD) {
            addRide(data)
                .then(() => {
                    history.replace(Links.main + Links.travelAllowance.ride.list);
                })
                .catch((error) => {
                    setToast(t("travelAllowance.ride.addError"));
                    setShowToast(true);

                    console.error(error);
                })
                .finally(() => {
                    setShowLoader(false);
                })
        }

        if (type === RidePaneType.EDIT && rideDefaultValues?.id) {
            editRide(rideDefaultValues.id, data)
                .then(() => {
                    history.replace(Links.main + Links.travelAllowance.ride.list);
                })
                .catch((error) => {
                    setToast(t("travelAllowance.ride.editError"));
                    setShowToast(true);

                    console.error(error);
                })
                .finally(() => {
                    setShowLoader(false);
                })
        }
    };

    const prepareRideOnSubmit = (ride: RideForm) => {
        const {passengers, route, ...rideRest} = ride;
        const {id, ...routeRest} = route;

        return {
            ...rideRest,
            ...(id) ? {route: id} : {route: routeRest},
            ...(passengers !== undefined && {passengers: preparePassengersOnSubmit(ride)}),
        };
    }

    const prepareBackRideOnSubmit = (backRide: BackRideForm | undefined) => {
        if (backRide) {
            const {passengers, ...rest} = backRide;

            return {
                ...rest,
                ...(passengers !== undefined && {passengers: preparePassengersOnSubmit(backRide)}),
            };
        }
    }

    const preparePassengersOnSubmit = (rideData: RideForm | BackRideForm | undefined) => {
        if (rideData !== undefined && rideData.passengers !== undefined) {
            return rideData.passengers.map((passenger: PassengerFormField) => {
                return passenger.workerId;
            })
        }
    }

    const handleCommentForCoordinatorChange = (commentForCoordinator: string) => {
        form.setValue('comment', commentForCoordinator);
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            {
                <StyledTravelAllowanceContent>
                    <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>

                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={toast}
                        duration={6000}
                        position="top"
                        color="danger"
                    />
                    {loading
                        ? (
                            <>
                                <MyDataSkeleton></MyDataSkeleton>
                                <MyDataSkeleton></MyDataSkeleton>
                                <MyDataSkeleton></MyDataSkeleton>
                                <MyDataSkeleton></MyDataSkeleton>
                                <MyDataSkeleton></MyDataSkeleton>
                            </>
                        )
                        : (
                            <RideFormContext.Provider value={{
                                type: type,
                                rideRoutes: rideRoutes,
                                cars: cars,
                                passengers: possiblePassengers,
                                rideDefaultValues: rideDefaultValues,
                                setCoordinator: setCoordinator,
                                coordinator: coordinator,
                                setPlaceOfService: setPlaceOfService,
                                placeOfService: placeOfService,
                                rideChanges: rideChanges,
                            }}>
                                <FormProvider {...form}>
                                    <Form.Container onSubmit={form.handleSubmit(onSubmit)}>
                                        {
                                            rideDefaultValues?.commentForWorker
                                                ? <StyledWarningInfo>
                                                    <div className="changes-header">
                                                        <WarningIcon/>
                                                        <span>{t('travelAllowance.ride.commentForWorker')}:</span>
                                                        <span>{rideDefaultValues.commentForWorker}</span>
                                                    </div>
                                                </StyledWarningInfo>
                                                : <></>
                                        }
                                        {
                                            rideChanges
                                                ? <StyledWarningInfo>
                                                    <div className="changes-header">
                                                        <WarningIcon/>
                                                        <span>{t('travelAllowance.ride.fieldsChangedByCoordinator')}</span>
                                                    </div>
                                                    <div className="changes-list">
                                                        {
                                                            Object.entries(rideChanges).map(([key, translation]) => {
                                                                return <span key={key}>{t(translation)}</span>;
                                                            })
                                                        }
                                                    </div>
                                                </StyledWarningInfo>
                                                : <></>
                                        }
                                        <RideFormFields/>
                                        {
                                            type === RidePaneType.ADD ? <BackRideFormFields/> : <></>
                                        }
                                        <Accordion isOpen={false} allowToggle={true}
                                                   title={t('travelAllowance.ride.commentForCoordinator')}>
                                            {
                                                <section>
                                                    <CommentForCoordinatorModal isOpen={isCommentForCoordinatorModalOpen}
                                                                                onClose={() => setIsCommentForCoordinatorModalOpen(false)}
                                                                                setValue={(value) => handleCommentForCoordinatorChange(value)}
                                                    />
                                                    <IonRow>
                                                        <IonCol size="12" className="label mt-8 required">
                                                            {t('travelAllowance.ride.commentForCoordinator')}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="12" className="label">
                                                            <StyledInput
                                                                onClick={() => setIsCommentForCoordinatorModalOpen(true)}
                                                                ref={form.register()}
                                                                name={'comment'}
                                                                readOnly={true}
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                </section>
                                            }
                                        </Accordion>
                                        <StyledButton type="submit">
                                            <div className="btn center">
                                                <SaveIcon/>
                                                {
                                                    rideChanges
                                                        ? <span>{t('travelAllowance.sendToVerify')}</span>
                                                        : <span>{t('travelAllowance.save')}</span>
                                                }
                                            </div>
                                        </StyledButton>
                                    </Form.Container>
                                </FormProvider>
                            </RideFormContext.Provider>
                        )
                    }
                </StyledTravelAllowanceContent>
            }
        </Pane>
    );
};

export default RideAddEditPane;